import React from "react";
import {Text,Box,Heading,SimpleGrid,HStack} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image"
import Link from '../components/gatsbylink'
import {motion} from 'framer-motion'
import MySeo from '../components/seo'

export default function Beitrag3() {
    return(
        <motion.div
        initial={{opacity:0,scale:0.9,paddingBottom:"100px",maxWidth:"900px"}}
        animate={{opacity:1,scale:1}}
        transition={{duration:.7,ease:"easeInOut"}}
        >
<MySeo title="Virtuelle Rekonstruktion der Synagogen von Simmern und Hottenbach" description="Hunsrück-Museum Simmern"/>
            
                <HStack  mb="15"  justifyContent="space-between">

<Box>
<StaticImage
  src="../images/solo/virtuell2.jpeg" alt="Synagogenbild"
  layout='constrained'
  height={450}
  width={500}

  />
   <Text variant="Bild">©Foto: Forst-Mayer Studien- und Begegnungszentrum</Text>
</Box>
<Box>
<StaticImage
  src="../images/solo/virtuell1.jpeg"
  alt="Virtuelle Synagogenabbildung"
  layout='constrained'
  height={450}
  width={500}
/>
<Text variant="Bild">©Foto: Forst-Mayer Studien- und Begegnungszentrum</Text>
</Box>

</HStack>

        <Box  mt="40px">
            <Heading variant="Titel">
            Virtuelle Rekonstruktion der Synagogen von Simmern und Hottenbach
            </Heading>
            <SimpleGrid gap={4} columns={[1,1,2]}>
            <Box>
            <Text variant="solid">
            Die nationalsozialistische Gewaltherrschaft hatte zum Ziel, alle Jüdinnen und Juden und mit ihnen die jüdische Kultur zu vernichten. Dazu gehörten vor allem die Zentren des religiösen Lebens, die Synagogen und die – in kleinen Orten vorhandenen – „Betsäle“ oder „Betstuben“. 
            </Text>
            <Text variant="solid">
            Nach der Zerstörung des Tempels in Jerusalem durch die Römer im Jahr 70 nach christlicher Zeitrechnung lebten Jüdinnen und Juden zerstreut über die ganze Welt. Überall, wo sie auch hinkamen, errichteten sie Synagogen und erhielten damit die hebräische Sprache, religiöse Riten, jüdische Werte und so ihre Religion am Leben. Im Laufe der Zeit diente die Synagoge einem dreifachen Zwecke: Sie war nicht nur Stätte des Gebets, sondern auch des Lernens und der Begegnung.
            </Text>
            <Box mb="3"> 
                <StaticImage src="../images/beitrag8/3-Hottenbach_Synagoge.jpg" alt="Synagoge Hottenbach. Altes Bild" />
                <Text variant="Bild">Ausschnitt einer historischen Ansichtskarte von Hottenbach, 1903</Text>
                </Box>
            <Box>
            <StaticImage src="../images/beitrag8/4-Synagoge_Hottenbach.jpeg" alt="Synagoge Hottenbach" />
            <Text variant="Bild">Das Synagogengebäude in Hottenbach, 2021</Text>
            </Box>
            <Text variant="solid">
            
            Das Wort Synagoge stammt aus dem Griechischen und bedeutet „Vereinigung“ oder „Versammlung“. Im weitesten Sinne ist jeder Ort, an dem ein sogenannter „Minjan“ zum Lesen der Thora zusammenkommt, eine Synagoge. Als Minjan wird die Gemeinschaft von zehn religionsmündigen, jüdischen Männern (in vielen reformorientierten Gemeinden auch Frauen) bezeichnet, die sich zum Gebet zusammenfinden. Diese Gemeinschaft ist nicht auf die Anwesenheit eines Rabbiners angewiesen, denn jeder Jude darf aus der Tora lesen oder vorbeten, d.h. den Gottesdienst leiten. Die weitgehende Autonomie dieser Religionsgemeinschaften hatte begünstigt, dass sich auch in den vielen Dörfern und Kleinstädten zwischen Nahe, Rhein und Mosel kleine Gemeinden bilden konnten. Denn einen eigenen Rabbiner konnten sich die armen Landgemeinden nicht „leisten“. In der Regel wurden die Gottesdienste geleitet vom Religionslehrer, der meist auch als Schächter (Schochet) und Kantor (Chasan) fungierte, oder von Mitgliedern der Gemeinde, die eine gute Stimme besaßen.
            </Text>
            <StaticImage src="../images/beitrag8/2-SynSimmern_innen.jpg" alt="Synagoge Simmern" />
            <Text variant="Bild">Innenaufnahme der Synagoge in Simmern, 1911</Text>
            </Box>
            
            <Box>
            <Text variant="solid">
            In amtlichen Dokumenten und auch im Volksmund wird die Synagoge oft als „Jude(n)schul“ bezeichnet – ein Begriff, der bereits im Mittelalter geprägt wurde. Denn in der Synagoge wurden die Jungen im Lesen religiöser Texte unterrichtet. Doch auch für erwachsene Männer ist das Studium der Tora und des Talmud eine lebenslange Aufgabe. Lebhaft und laut werden dabei Textstellen zitiert und gemeinsam diskutiert. Noch immer wird die Synagoge von vielen Juden auf der ganzen Welt mit dem deutschen Wort „Schul“ bezeichnet.
            </Text>
            <Text variant="solid">
            Von den über 30 jüdischen Versammlungshäusern in der Rhein-Hunsrück-Region haben nur wenige die Arisierungen, die Reichspogromtage 1938 und die darauffolgenden Jahrzehnte des Verschweigens, Vergessens und Verdrängens überstanden. Von vielen Synagogen auf dem Land existieren keine Fotos, von einigen nur Pläne oder Zeichnungen. Innenaufnahmen sind äußerst selten, sodass im Rhein-Hunsrück-Kreis nur ein einziges Foto eines Sakralraumes überliefert ist, das anlässlich der Einweihung der Simmerner Synagoge aufgenommen wurde.
            </Text>
            <Box>
            <StaticImage src="../images/beitrag8/1-SynSimmern_außen.jpg" alt="Synagoge Simmern" />
            <Text variant="Bild">Außenaufnahme der Synagoge in Simmern, 1911</Text>
            </Box>
          
           
            <Text variant="solid">
            Der Förderkreis Synagoge Laufersweiler hat für die Ausstellung „Erwachet aus dem langen Schlafe – Jüdisches Leben auf dem Lande, Juden im Hunsrück“ (2019/2020) eine virtuelle Rekonstruktion der 1911 erbauten und 1950 abgerissenen Simmerner Synagoge anfertigen lassen. Diese entstand im Rahmen des Projekts "KuLaDig"  und orientierte sich an der im Äußeren noch erkennbaren Synagoge in Hottenbach. (2022). Diese beiden virtuellen Rekonstruktionen werden im Verbund mit den Schautafeln zu den Bacharacher Juden in der Josefskapelle in Bacharach gezeigt, um einen Eindruck des religiösen Lebens auf dem Land zu vermitteln. 
            </Text>
            <Text pt="4">
            Zum Beitrag aus KuLaDig über die Synagoge in Hottenbach. Hier befinden sich weitere Infos zur Synagoge, zur jüdischen Gemeinde als auch die virtuelle Rekonstruktion:
           
            </Text>
            
            <Link href="https://www.kuladig.de/Objektansicht/KLD-343515">  
            <Text _hover={{color:"teal"}} color="blue.400"  variant="solid">&rarr; Weitere Informationen</Text>
            </Link>
          
            <Link to="/synagogen"><Text _hover={{color:"teal"}} color="blue.400"  variant="solid">&rarr; Historische Fotos von Synagogen</Text></Link>
                </Box>
          
           
            </SimpleGrid>
            
         
        
          
            
        </Box>
        </motion.div>
    )
}